<!-- 
  This is the sign in page, it uses the dashboard layout in: 
  "./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in">
    <Loading v-if="loading" />
    <!-- <a class="sideline" :href="lineHref" target="_blank">
      <img
        v-bind:src="'/images/logos/line.png'"
        class="logo-line mb-5"
        style="width: 30px"
      />
    </a> -->
    <a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">
      <a-col
        :span="24"
        :md="12"
        :lg="{ span: 12, offset: 0 }"
        :xl="{ span: 6, offset: 0 }"
        class="col-form"
      >
        <img
          class="center"
          src="images/superbot.png"
          alt=""
          style="max-width: 330px"
        />

        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
          :hideRequiredMark="true"
        >
          <a-form-item class="mb-10" label="Username" :colon="false">
            <a-input
              v-decorator="[
                'username',
                {
                  rules: [
                    { required: true, message: 'Please input your username!' },
                  ],
                },
              ]"
              placeholder="Username"
            />
          </a-form-item>
          <a-form-item class="mb-5" label="Password" :colon="false">
            <a-input
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: 'Please input your password!' },
                  ],
                },
              ]"
              type="password"
              placeholder="Password"
            />
          </a-form-item>
          <p class="text-center text-danger" v-if="error">{{ errorMsg }}</p>
          <!-- <a-form-item class="mb-10"
              <a-switch v-model="rememberMe" /> Remember Me
          </a-form-item> -->
          <a-form-item>
            <a-button
              block
              html-type="submit"
              type="primary"
              class="login-form-button"
            >
              เข้าสู่ระบบ
            </a-button>
          </a-form-item>
        </a-form>
        <!-- / Sign In Form -->
        <p class="mt-10 text-white">
          *หมายเหตุ เข้าใช้งานยูสและรหัส zigma69 เท่านั้น
        </p>

        <a-button
          block
          :href="registerHref"
          target="_blank"
          class="register-button mb-10"
        >
          สมัครสมาชิก</a-button
        >
        <a-button
          block
          class="header_button_size mr-10"
          style="background-color: #00b900"
          :href="lineHref"
          target="_blank"
        >
          <img src="images/line.png" alt="line" class="header_line" />
          <span style="color: white"> ติดต่อสอบถาม @superbot </span>
        </a-button>
      </a-col>

      <!-- / Sign In Form Column -->

      <!-- Sign In Image Column -->
      <!-- <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
        <img src="images/bot_logo.jpg" alt="" />
      </a-col> -->
      <!-- Sign In Image Column -->
    </a-row>
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";

export default {
  computed: {
    registerHref: function () {
      return process.env.VUE_APP_REGISTER_HREF;
    },
    lineHref: function () {
      return process.env.VUE_APP_LINE_HREF;
    },
  },

  components: {
    Loading,
  },
  data() {
    return {
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      loading: false,
      error: false,
      errorMsg: "",
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        this.loading = true;
        if (!err) {
          this.$http
            .post(`${process.env.VUE_APP_BACKEND_API_URL}/api/auth/login`, {
              username: values.username,
              password: values.password,
            })
            .then(({ data }) => {
              this.$store.dispatch("setDisplayName", values.username);
              this.$store.dispatch("setToken", data.token).then((data) => {
                this.$router.push("/").then(() => {
                  window.location.reload();
                });
              });
            })
            .catch((err) => {
              console.log(err);
              this.errorMsg = err.response.data.message;
              this.error = true;
              this.loading = false;
              values.password = "";
              return;
            });
          // console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="scss">
body {
  background-color: #161616;
}
.login-form-button {
  // background-color: #029bde;
  // border-color: #029bde;
}
.register-button {
  background-color: #e9960f;
  border-color: #e9960f;
  color: white;
}
.register-button:hover {
  background-color: #8a5706;
  border-color: #8a5706;
  color: white;
}
// .sideline {
//   overflow: hidden;
//   background: rgba(255, 203, 0, 1);
//   background: /*radial-gradient(ellipse farthest-corner at right bottom, #89734C 0%, #89734C 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffac 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);*rgb(53 3 3 / 61%)*/ radial-gradient(
//       ellipse farthest-corner at right bottom,
//       #45bf64 0%,
//       #65d279 8%,
//       #41d05b 30%,
//       #3cc33c 40%,
//       transparent 80%
//     ),
//     radial-gradient(
//       ellipse farthest-corner at left top,
//       #acffc9 0%,
//       #acffbb 8%,
//       #64d174 25%,
//       #58da7d 62.5%,
//       #2c9233 100%
//     );
//   position: fixed;
//   border-bottom-left-radius: 10px;
//   border-top-left-radius: 10px;
//   top: 50%;
//   right: 0;
//   text-align: center;
//   font-size: 12px;
//   padding: 8px;
//   color: white;
//   z-index: 1001;
//   -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
//   -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
//   box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
//   cursor: pointer;
// }
</style>
