<template>
  <div class="load-panel load-bg" v-if="loading">
    <div class="spinner">
      <clip-loader :color="color" :size="size"></clip-loader>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    ClipLoader,
  },
  props: {
    loading: { type: Boolean, default: true },
  },
  data() {
    return {
      color: "#fff",
      size: "44px",
    };
  },
};
</script>
<style lang="scss">
.load-panel {
  display: flex;
  justify-content: center;
  top: 0px;
  left: 0px;
  position: fixed;

  width: 100%;
  height: 100%;
  z-index: 9999;
}

.load-bg {
  min-height: 100vh;
  display: flex;
  background-color: #000000ce;
}
.spinner {
  align-content: center;
  align-items: center;
  align-self: center;
}
</style>
